export const USER_STATUSES = [
	{ title: 'Inactive', class: 'inactive' },
	{ title: 'Active', class: 'success' },
];

export const USER_GROUP_OPTIONS = [
	'All group',
	'Super admin',
];

export const USER_STATUS_OPTIONS = [
	{ name: 'All Status', value: null },
	{ name: 'Active', value: 1 },
	{ name: 'Inactive', value: 0 },
];

export const USER_TABLE_FIELDS = [
	{ key: 'createdAt', label: 'Create Date', class: 'w-25' },
	{ key: 'staffId', label: 'ID Staff', class: 'w-25' },
	{ key: 'username', label: 'Username', class: 'w-25' },
	{ key: 'name', label: 'Name', class: 'w-25' },
	{ key: 'storeName', label: 'Store' },
	{ key: 'provider', label: 'Provider' },
	{ key: 'storeItecId', label: 'Store ID' },
	{ key: 'group', label: 'Group' },
	{ key: 'approved', label: 'Approved' },
	{ key: 'status', label: 'Status' },
];

export const USER_PROVIDER_OPTIONS = [
	{ name: 'All provider', value: null },
	{ name: 'IT Easy', value: 'iteasy' },
	{ name: 'Password', value: 'password' },
];
export const USER_APPROVED_STATUS_OPTIONS = [
	{ name: 'All Status', value: null },
	{ name: 'Pending', value: 'pending' },
	{ name: 'Approved', value: 'approved' },
	//  ?? FYI: user list dont show rejected
	// { name: 'Rejected', value: 'rejected' },
];
export const APPROVED_STATUS = {
	//  ?? FYI: user list dont show rejected
	// rejected: {
	// 	title: 'Rejected',
	// 	class: 'alert',
	// },
	pending: {
		title: 'Pending',
		class: 'hypertext',
	},
	approved: {
		title: 'Approved',
		class: 'success',
	},
};
